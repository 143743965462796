import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/page_title'
import * as style from '../styles/404.module.css'

function NotFoundContents({ showMenu }) {
  return (
    <div className='page_contents'>
      <PageTitle>404 - Page Not Found</PageTitle>

      <div className={`${style.main} wide_content`}>
        <div className={style.text}>
          Sorry, we couldn't find the page you were looking for.
        </div>

        <div
          className={style.button}
          role='button'
          tabIndex={0}
          onClick={showMenu}
          onKeyDown={e => e.code === 'Enter' && showMenu()}
        >
          Try another page
        </div>
      </div>
    </div>
  )
}

export default function NotFoundPage() {
  return (
    <Layout showFooter={true}>
      <Seo
        title='404 - Page Not Found'
        description='Sorry, we were unable to find the page you were looking for. Click here to search for the correct page.'
      />
      <NotFoundContents />
    </Layout>
  )
}
